<!-- 通知列表 -->
<template>
  <div id="informlist">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          title="通知公告"
          left-text
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
        <p style="font-size: 26px;color: white;margin: 5px 10px;">{{ userName }},{{ time }}</p>
        <van-search
          v-model="summary"
          placeholder="请输入搜索关键词"
          shape="round"
          background="rgba(255,255,255,0)"
          style="color:white"
        />
      </div>
      <!-- <div class="cardstyle"> -->
      <van-loading
        v-if="loading"
        size="24px"
        type="spinner"
        color="#1989fa"
        style="text-align:center"
      >加载中...</van-loading>
      <!-- <div :style="conheight" style="border-radius: 10px"> -->
      <van-list
        v-model="isUpLoading"
        :immediate-check="false"
        :finished="upFinished"
        finished-text="没有更多了"
        :offset="offset"
        style="margin-top:-60px"
        @load="oncheck"
      >
        <div v-if="showvacuous" class="boxstyle">
          <div
            style="background-color: transparent;font-size:18px;margin-top: -30px;border-radius: 10px;text-align:center;height:173px;line-height: 173px;"
          >暂无数据</div>
        </div>
        <div v-if="hidebox">
          <van-row v-for="(item, index) in list" :key="index" class="boxstyle">
            <van-col span="24" style="padding: 20px" @click="doMethodto(item)">
              <van-col span="24">
                <van-cell
                  :title="item.title"
                  is-link
                  class="title"
                  style="padding: 0px 10px 0px 10px;font-size:14px;height: 24px;line-height: 24px;margin-bottom:0px"
                />
              </van-col>
              <!-- <van-col span="24">
                  <span class="content" style="font-size:12px;color:#999999;">{{item.content}}</span>
                </van-col> -->
            </van-col>
            <!-- <van-col span="24">
                <van-divider dashed v-if="divdas"></van-divider>
              </van-col> -->
          </van-row>
        </div>
      </van-list>
      <!-- </div>
      </div> -->
    </div>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
// import { pageListReiForm } from '@/api/reiform'
// import ReiTabbar from '../components/ReiTabbar.vue'
import { pageMessageList } from '@/api/message'
export default {
  name: 'Project',
  components: {
    // ReiTabbar
  },
  data() {
    return {
      hidebox: true,
      showvacuous: false,
      value: '',
      summary: '',
      page: 10,
      pageCurrent: 1,
      title: '',
      list: [],
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      time: '',
      userName: '',
      proStatus: 1,
      imagecard: [require('../../assets/images/news1.png')],
      loading: false,
      divdas: false,
      conheight: {
        height: '',
        width: '100%',
        overflow: 'auto'
      }
    }
  },
  watch: {
    // 监听查询框数据
    summary() {
      this.getList()
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #EFF6FF')
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
    this.getList()
    const now = new Date()
    const hour = now.getHours()
    if (hour < 6) {
      this.time = '凌晨好！'
    } else if (hour < 9) {
      this.time = '早上好！'
    } else if (hour < 12) {
      this.time = '上午好！'
    } else if (hour < 14) {
      this.time = '中午好！'
    } else if (hour < 17) {
      this.time = '下午好！'
    } else if (hour < 19) {
      this.time = '傍晚好！'
    } else if (hour < 22) {
      this.time = '晚上好！'
    } else {
      this.time = '夜里好！'
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    getHeight() {
      this.conheight.height = window.innerHeight - 200 + 'px'
    },
    oncheck() {
      // 上拉调用此函数
      setTimeout(() => {
        this.pageCurrent = this.pageCurrent + 1
        pageMessageList(this.pageCurrent, this.page, this.summary).then(
          response => {
            if (response.data.records === '') {
              this.upFinished = true
              this.$toast('没有数据了') // 弹出
              return false
            } else {
              this.list = this.list.concat(response.data.records)
              this.$toast(this.$t('msg.loadSuccess')) // 弹出
              this.isUpLoading = false
            }
          }
        )
      }, 500)
    },
    onClickLeft() {
      this.$router.back(-1)
    },
    doMethodto(item) {
      this.$router.push({
        name: 'InformDetail',
        params: {
          title: item.title,
          content: item.content,
          updateDate: item.updateDate
        }
      })
    },
    getList() {
      this.showvacuous = false
      this.hidebox = false
      this.loading = true
      this.divdas = false
      var time = new Date()
      this.time = time.toLocaleString().slice(10, 12) // 获取日期与时间
      this.pageCurrent = 1
      setTimeout(() => {
        pageMessageList(this.pageCurrent, this.page, this.summary).then(
          response => {
            this.list = response.data.records
            this.loading = false
            this.divdas = true
            this.hidebox = true
          }
        )
      }, 1000)
    },
    getUserInfo() {
      var msg = JSON.parse(localStorage.getItem('UserMsg'))
      this.userName = msg.name
    }
  }
}
</script>
<style scoped lang="scss">
#informlist {
  position: relative;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background-color: #eff6ff;
  //   padding-bottom: 3rem;
  .head {
    // border-radius: 0 0 10% 10%;
    height: 200px;
    width: 100%;
    background-image: url("../../assets/images/top_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .cardstyle {
    width: 90%;
    margin-top: -4rem;
    margin-left: 1rem;
    background-color: #fff;
    padding: 16px 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px #dde9f3;
  }
  .title {
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  .content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 16px;
    // height: 32px;
    // margin-bottom: 10px;
    font-size: 12px;
    padding: 5px 10px 0px;
  }
  .boxstyle {
  margin: 0 15px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  height: 60px;
}
  .detail {
    // width: 100%;
    border-radius: 1rem;
    font-size: 10px;
  }
  .updata {
    // width: 100%;
    border-radius: 1rem;
    background-color: #ededed;
    color: #a1a1a1;
    font-size: 10px;
    float: left;
    margin-left: 10px;
  }
  .van-button__text {
    padding: 0.5rem;
  }
}
</style>
<style>
#informlist .van-field__left-icon {
  color: white;
}

#informlist .van-search__content--round {
  background-color: rgba(0, 0, 0, 0.2);
}

#informlist .van-field__control {
  color: white;
}
#informlist .van-nav-bar .van-icon {
  color: white;
}
#informlist .van-nav-bar__title {
  color: white;
  font-size: 18px;
}
#informlist .van-nav-bar__arrow {
  font-size: 22px;
}
#informlist .van-hairline--bottom::after {
  border-bottom-width: 0;
}
</style>

