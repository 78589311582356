import request from '@/utils/request'

// 查询系统消息列表
export function pageMessageList(pageCurrent, pageSize, title) {
  return request({
    url: 'system/message/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'title': title
    }
  })
}

// 创建系统消息
export function createSysMessage(data) {
  return request({
    url: 'system/message',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 修改系统消息方法
export function updateSysMessage(data) {
  return request({
    url: 'system/message',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 删除系统信息
export function deleteSysMessage(ids) {
  return request({
    url: 'system/message/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'ids': ids
    }
  })
}

// 首页查询消息列表
export function messageList() {
  return request({
    url: 'system/message/messageList',
    method: 'get'
  })
}
// 首页查询消息列表
export function quickeList() {
  return request({
    url: 'reimburse/reiTask/getUserTaskNumber',
    method: 'get'
  })
}
